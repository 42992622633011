import store from '@/store'
import constant from '@/global/const'
import util from '@/global/util'
import { computed } from '@vue/composition-api'
import http from '@/global/http'

export default function useCustomer() {
  const { parseFieldsToNumber } = util

  const routeName = computed(() => store.state.app.currentRouteName)
  const customerMenuType = computed(() => {
    if (routeName.value.includes('corner')) return 'corner'
    if (routeName.value.includes('hotel')) return 'hotel'
    return 'office'
  })
  const customerListMenuType = computed(() => (routeName.value.includes('manage') ? 'manage' : 'list'))

  const formatClientData = (clientData = {}) => {
    // những trường này sẽ được chuyển thành kiểu number
    const NumberField = ['price_from', 'price_to', 'price', 'input_price', 'width',
      'length', 'acreage', 'max_area', 'floor_number', 'max_floor_number', 'contract_time',
      'ward_id', 'province_id', 'room_from', 'room_to', 'min_price', 'max_price']

    return parseFieldsToNumber(clientData, NumberField)
  }

  const renderCustomerStructure = (data, showArea = true) => [
    showArea && data.width && data.length ? (`${data.width}x${data.length}m, `) : '',
    data.basement ? '1 hầm, ' : '',
    '1 trệt, ',
    data.badger ? '1 lửng, ' : '',
    data.floor_number ? `${data.floor_number} lầu, ` : '',
    data.elevator ? 'thang máy, ' : '',
    data.terrace ? 'ST' : '',
  ].join('')

  const renderCustomerTitle = (data, showAllAddress = false) => {
    let districts = ''
    let streets = ''

    if (showAllAddress) {
      const arrD = []
      data.district_ids.forEach((dId, idx) => {
        const districtInfo = store.state.app.districts.find(item => item.id === dId) || {}
        if (idx !== 0) {
          arrD.push(`${idx + 1}. ${districtInfo.name}`)
        } else {
          arrD.push('')
          arrD.push('Các quận sau như sau: ')
          arrD.push(`${idx + 1}. ${districtInfo.name}`)
        }
      })
      districts = arrD.join('<br/>')

      const arrS = []
      data.street_ids.forEach((sId, idx) => {
        const streetInfo = store.state.app.streetsByDistricts.find(item => item.id === sId) || {}
        if (idx !== 0) {
          arrS.push(`${idx + 1}. ${streetInfo.pre || ''}${streetInfo.name}`)
        } else {
          arrS.push('các tuyến đường như sau: ')
          arrS.push(`${idx + 1}. ${streetInfo.pre || ''}${streetInfo.name}`)
        }
      })
      streets = arrS.join('<br/>')
    } else {
      for (let i = 0; i < Math.min(2, data.district_ids.length); i += 1) {
        if (i !== 0) {
          districts += ', '
        }
        const districtInfo = store.state.app.districts.find(item => item.id === data.district_ids[i]) || {}
        districts += districtInfo.name
      }
      for (let i = 0; i < Math.min(2, data.street_ids.length); i += 1) {
        if (i !== 0) {
          streets += ', '
        } else {
          streets += 'Đường '
        }
        const streetInfo = store.state.app.streetsByDistricts.find(item => item.id === data.street_ids[i]) || {}
        streets += streetInfo.name
      }
    }

    const title = [
      `${data.host_name} `,
      `cần ${constant.FORMALITY_CUSTOMER.find(item => item.value === data.formality)?.text?.toLowerCase()} `,
      `${constant.TYPE_PRODUCT.find(item => item.value === data.type)?.text} `,
      `${streets || ''} `,
      `${districts || ''} `,
    ].join('')
    return title
  }

  const renderCustomerContent = data => {
    let renderContent = ''
    const title = renderCustomerTitle(data, true)
    renderContent = [
      title,
      `- Diện tích yêu cầu tối thiểu: ${data.width && data.length ? `${data.width}x${data.length}m` : '...'}`,
      `- Kết cấu yêu cầu tối thiểu: ${renderCustomerStructure(data, false)}`,
    ]
    switch (data.formality) {
      case 'thue':
        renderContent.push(
          `- Giá thuê tối đa cho 1 cửa hàng: ${data.price ? `${data.priceDisplay}/tháng chưa VAT` : '...'}`,
          '- Thời hạn cho thuê: ...',
          'Mô tả chi tiết: ...',
        )
        break
      case 'mua':
        renderContent.push(
          `- Giá bán tối đa cho 1 cửa hàng: ${data.price ? data.priceDisplay : '...'}`,
          'Mô tả chi tiết: ...',
        )
        break
      default:
        break
    }
    return renderContent.join('<br/>')
  }

  const renderCustomerOfficeStructure = data => [
    data.floor_number ? `${data.floor_number} lầu, ` : '',
    data.basement_number ? `${data.basement_number} hầm, ` : '',
    data.elevator_number ? `${data.elevator_number} thang máy` : '',
  ].join('')

  const renderCustomerOfficeTitle = (data, showAllAddress = false) => {
    const title = [
      `${data.company_name} cần thuê Văn Phòng khu vực ${data.region_description} `,
      `với diện tích ${data.area_description} `,
      `(${data.budget_description})`,
    ].join('')
    return title
  }

  const renderCustomerOfficeContent = data => {
    const renderContent = [
      `${data.company_name} cần thuê Văn Phòng`,
      `Diện tích: ${data.area_description}`,
      `Khu vực: ${data.region_description}`,
      `Ngân sách: ${data.budget_description}`,
      `Khác: ${data.more_description}`,
      '',
      'Chi tiết:',
      `- Số ô-tô xe máy: ${data.vehicle_description}`,
      `- Ngoài giờ: ${data.over_time_description}`,
      `- Thời gian hợp đồng: ${data.contract_time} năm`,
      '',
      'Liên hệ:',
      `- ${data.host_name} (${data.host_phone1})`,
      `- Email: ${data.email}`,
    ]
    return renderContent.join('<br/>')
  }

  // api
  const checkCustomerUnique = (data, cb) => http.handle(store.dispatch('customer/checkCustomerUnique', data), cb)
  const createCustomer = (data, cb) => http.handle(store.dispatch('customer/createCustomer', formatClientData(data)), cb)
  const fetchCustomer = (id, cb) => http.handle(store.dispatch('customer/fetchCustomer', { id }), cb)
  const quotation = (data, cb) => http.handle(store.dispatch('customer/quotation', data), cb)
  const updateCustomer = (data, cb) => http.handle(store.dispatch('customer/updateCustomer', formatClientData(data)), cb)
  const closeDealCustomer = (data, cb) => http.handle(store.dispatch('customer/closeDealCustomer', data), cb)
  const openDealCustomer = (id, cb) => http.handle(store.dispatch('customer/openDealCustomer', id), cb)
  const deleteCustomer = (id, cb) => http.handle(store.dispatch('customer/deleteCustomer', id), cb)
  const fetchSuggestedListProduct = (data, cb) => http.handle(store.dispatch('customer/fetchSuggestedListProduct', data), cb)
  const exportExcel = (q, cb) => http.handle(store.dispatch('customer/exportExcel', q), cb)

  return {
    customerMenuType,
    customerListMenuType,
    formatClientData,
    renderCustomerStructure,
    renderCustomerTitle,
    renderCustomerContent,
    renderCustomerOfficeStructure,
    renderCustomerOfficeTitle,
    renderCustomerOfficeContent,
    //
    exportExcel,
    checkCustomerUnique,
    createCustomer,
    fetchCustomer,
    quotation,
    updateCustomer,
    closeDealCustomer,
    openDealCustomer,
    deleteCustomer,
    fetchSuggestedListProduct,
  }
}
