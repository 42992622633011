<template>
  <b-form-group
    :id="'b-form-group-' + id"
    :ref="'b-form-group-' + id"
    :label="label"
    :label-for="'b-form-group-input-' + id"
    :valid-feedback="validFeedback"
    :invalid-feedback="formErrorText"
    :state="formState"
    :class="addClass"
  >
    <!--    form label-->
    <template
      v-if="required && required.includes('required')"
      slot="label"
    >
      <span :class="{'text-danger': formErrorText}">{{ label }} <span class="text-danger">(*)</span></span>
    </template>
    <!--    input-->
    <b-input-group :class="appendText || prependText ? 'input-group-merge' : ''">
      <b-input-group-prepend
        v-if="prependText || prependIcon"
        is-text
        :class="prependClass"
      >
        <span v-if="prependText">{{ prependText }}</span>
        <span
          v-if="prependIcon"
          :class="prependClass"
        >
          <feather-icon
            v-b-tooltip.hover.html="prependTooltip"
            :icon="prependIcon"
          />
        </span>
      </b-input-group-prepend>

      <b-form-input
        v-if="INPUT_TYPE.includes(type)"
        :id="'b-form-group-input-' + id"
        :key="'b-form-group-input-key-' + id"
        v-model="formValue"
        :value="value"
        :state="formState"
        :type="type"
        :disabled="disabled"
        :placeholder="placeholder"
        :class="appendText || prependText ? 'input-group-merge' : ''"
        trim
        :list="'b-form-group-input-datalist-' + id"
        @input="updateValue"
      />

      <b-input-group-append
        v-if="appendText || appendIcon"
        is-text
        :class="appendClass"
      >
        <span v-if="appendText">{{ appendText }}</span>
        <span
          v-if="appendIcon"
          :class="appendClass"
        >
          <feather-icon
            v-b-tooltip.hover.html="appendTooltip"
            :icon="appendIcon"
          />
        </span>
      </b-input-group-append>

      <template
        v-if="withDropdownOptions && withDropdownOptions.length"
        #append
      >
        <b-dropdown
          v-model="formSubValue"
          variant="outline-primary"
          :value="subValue"
          :text="subValue ? (withDropdownOptions.find(item => item.value === subValue) || {}).text : 'Đơn vị'"
        >
          <b-dropdown-item
            v-for="(item, idx) in withDropdownOptions"
            :key="'b-form-group-input-dropdown-key-' + id + '-' + idx"
            @click="updateSubValue(item.value)"
          >
            {{ item[textField] }}
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <datalist
        v-if="type === 'search' && autocompleteList.length"
        :id="'b-form-group-input-datalist-' + id"
        :key="'b-form-group-input-datalist-key-' + id"
      >
        <option
          v-for="(item, idx) in autocompleteList"
          :key="'b-form-group-input-datalist-option-' + id + idx"
          :value="item[textField]"
        >
          <small>{{ item.pre || '' }}</small>
        </option>
      </datalist>
    </b-input-group>

    <!--    textarea-->
    <b-form-textarea
      v-if="type==='textarea'"
      :id="'b-form-group-textarea-' + id"
      v-model="formValue"
      :value="value"
      :placeholder="placeholder"
      :rows="rows"
      @change="updateValue"
    />

    <!--    radio-->
    <b-form-radio-group
      v-if="type==='radio'"
      :id="'b-form-group-radio-' + id"
      :key="'b-form-group-radio-key-' + id"
      v-model="formValue"
      :value="value"
      :state="formState"
      :options="options"
      :stacked="stacked"
      :value-field="valueField"
      :text-field="textField"
      @input="updateValue"
    />

    <!--    select-->
    <v-select
      v-if="type==='multi-select' || type==='select'"
      :id="'b-form-group-select-' + id"
      v-model="formValue"
      :label="textField"
      :input-id="valueField"
      :options="options"
      :selectable="val => !val.disabled"
      :reduce="option => option[valueField]"
      :multiple="type==='multi-select'"
      :clearable="clearable"
      :close-on-select="type!=='multi-select'"
      @input="updateValue"
    >
      <template
        v-if="withInfo"
        v-slot:option="option"
      >
        <div class="d-flex gap-1">
          <span class="avatar">
            <b-avatar
              size="40"
              class="avatar-border-2 box-shadow-1 badge-minimal"
              :src="getUrlFile(option.avatar)"
              alt="Ảnh đại diện"
            >
              <feather-icon
                v-if="!option.avatar"
                icon="UserIcon"
                size="22"
              />
            </b-avatar>
          </span>
          <span class="d-flex flex-column">
            <small class="font-weight-bolder">{{ option.fullname || 'Họ và tên' }}</small>
            <span>{{ option.phone || '' }}</span>
          </span>
        </div>
      </template>
    </v-select>

    <!--    rating-->
    <b-form-rating
      v-if="type==='rating'"
      :id="'b-form-group-rating-' + id"
      v-model="formValue"
      :stars="stars"
      :readonly="readonly"
      variant="primary"
      @change="updateValue"
    />

    <!--    checkbox-->
    <b-form-checkbox
      v-if="type==='checkbox'"
      :id="'b-form-group-checkbox-group-' + id"
      v-model="formValue"
      variant="primary"
      @input="updateValue"
    >
      {{ subLabel || label }}
    </b-form-checkbox>

    <!--    checkbox group-->
    <b-form-checkbox-group
      v-if="type==='checkbox-group'"
      :id="'b-form-group-checkbox-group-' + id"
      v-model="formValue"
      :value="value"
      :options="options"
      :stacked="stacked"
      @input="updateValue"
    />
  </b-form-group>
</template>

<script>

import {
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormRating,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  VBTooltip,
  BAvatar, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'

export default {
  components: {
    vSelect,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormRating,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BAvatar,
    BFormTextarea,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    value: {
      type: null,
    },
    withDropdownOptions: {
      type: Array,
      default: () => [],
    },
    // for b-dropdown-option
    subValue: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    autocompleteList: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    subLabel: {
      type: String,
      default: '',
    },
    rows: {
      type: String,
      default: '',
    },
    addClass: {
      type: String,
      default: '',
    },
    required: {
      type: String,
      default: '', // split by ' '
    },
    prependText: {
      type: String,
      default: '',
    },
    prependClass: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    prependTooltip: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    withInfo: {
      type: Boolean,
      default: false,
    },
    stars: {
      type: String,
      default: '5',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    appendText: {
      type: String,
      default: '',
    },
    appendClass: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    appendTooltip: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    valueField: {
      type: String,
      default: 'value',
    },
    textField: {
      type: String,
      default: 'text',
    },
    validFeedback: {
      type: String,
      default: '',
    },
    invalidFeedback: {
      type: String,
      default: '',
    },
    options: {
      type: [Array, Object],
      default: () => [],
    },
    selectDefault: {
      type: Boolean,
      default: true,
    },
    stacked: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    required(n) {
      if (!n) return
      this.checkFormValue()
    },
    value: {
      immediate: true,
      handler(n) {
        this.formValue = n
        this.checkFormValue()
      },
    },
    withDropdownOptions() {
      this.updateDefaultSubValue()
    },
    options() {
      this.updateDefaultValue()
    },
  },
  mounted() {
    this.canTouch = true
  },
  created() {
    this.updateDefaultValue()
    this.updateDefaultSubValue()
  },
  methods: {
    updateDefaultValue() {
      if (['select', 'radio'].includes(this.type) && this.options.length && this.selectDefault) {
        // nếu phần tử tồn tại trong danh sách mới thì ko làm gì
        if (this.options.find(item => item[this.valueField] === this.formValue)) return
        // nếu không thì cần chọn lại phần tử mặc định [0]
        const defaultValue = this.options[0][this.valueField]
        this.updateValue(defaultValue)
      }
    },
    updateDefaultSubValue() {
      if (this.withDropdownOptions && this.withDropdownOptions.length) {
        // nếu phần tử tồn tại trong danh sách mới thì ko làm gì
        if (this.withDropdownOptions.find(item => item[this.valueField] === this.formSubValue)) return
        // nếu không thì cần chọn lại phần tử mặc định [0]
        const defaultSubValue = this.withDropdownOptions[0][this.valueField]
        this.updateSubValue(defaultSubValue)
      }
    },
    checkFormValue() {
      if (this.canTouch) {
        const r = this.required
        if (!r) return
        const conditions = r.split(' ')
        for (let i = 0; i < conditions.length; i += 1) {
          const errText = this.validValue(this.label, this.value, conditions[i])
          if (errText) {
            this.formState = false
            this.formErrorText = errText
            return
          }
        }
        this.formState = true
        this.formErrorText = ''
      }
    },
    updateValue(val) {
      this.$emit('update-value', val)
    },
    updateSubValue(val) {
      this.$emit('update-sub-value', val)
    },
  },
  setup() {
    const formState = ref(null)
    const formValue = ref(null)
    const formSubValue = ref(null)
    const formErrorText = ref(null)
    const canTouch = ref(false)
    const today = new Date().toISOString()

    return {
      formValue,
      formSubValue,
      formState,
      formErrorText,
      canTouch,
      today,
    }
  },
}
</script>
